#avatarom .vacancy-container {
  margin-top: 30px;
}
#avatarom .vacancy-container .vacancy {
  margin-top: 0 !important;
}
#avatarom .vacancy-container .address-map {
  margin-top: 0 !important;
}
#avatarom .vacancy-container .vacancy-container-left {
  max-width: 750px;
}
#avatarom .vacancy-container .vacancy-container-left .category-header {
  padding-left: 10px;
}
#avatarom .vacancy-container .vacancy-container-left .professions-header {
  margin-top: 30px;
  padding-left: 10px;
}
#avatarom .vacancy-container .vacancy-container-left .professions-header a {
  text-decoration: underline;
  color: #404D6D;
}
#vacancy-container-button {
  display: none;
}
@media only screen and (max-width: 767px) {
  #avatarom .vacancy-container {
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px !important;
  }
  #avatarom .vacancy-container .vacancy {
    margin-top: 0 !important;
  }
  #avatarom .vacancy-container .vacancy-container-right {
    margin-top: 0px;
  }
  #avatarom .vacancy-container .vacancy-container-right .address-map .ya-address-map {
    margin: 0 auto;
  }
  #avatarom .vacancy-container .vacancy-container-right .company {
    width: 100%;
  }
  #vacancy-container-button {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 50px;
    border-radius: 0 !important;
    box-shadow: -3px -2px 3px grey;
  }
}
