.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: inherit;
  flex: 0 0 auto;
  justify-content: flex-end;
  background-image: url("/images/footer.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%;
}
.footer .footer-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: inherit;
  margin: 30px;
  flex-direction: column;
  justify-content: flex-end;
}
.footer .footer-links .link {
  padding: 2px;
}
@media only screen and (max-width: 767px) {
  #avatarom .footer .footer-links .link {
    color: #110D3B !important;
  }
}
