#avatarom .home.landing {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: inherit;
  background-image: url("/images/landing.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 650px;
  width: 100%;
  padding: 30px 60px;
}
#avatarom .home.landing .navigation {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: inherit;
}
#avatarom .home.landing .navigation .left a:nth-child(odd),
#avatarom .home.landing .navigation .right a:nth-child(odd) {
  margin-right: 30px;
}
#avatarom .home.landing .title .header {
  color: #fff;
  font-size: 3em;
  font-weight: 400;
}
#avatarom .home.landing .title .header.main {
  font-size: 2em;
}
#avatarom .home.landing .searching {
  margin-bottom: 100px;
}
#avatarom .home.landing .searching .input input {
  font-size: 16px;
  width: 350px;
  border-radius: 12px;
  padding: 16px;
}
#avatarom .home.landing .searching .input i {
  width: 1em;
  color: #CF5D40;
  font-size: 2em;
  width: 1.5em;
  opacity: 1;
}
#avatarom .home.landing .searching .search-button i {
  font-size: 2em;
}
#avatarom .home.landing .searching .example {
  padding-top: 10px;
  padding-left: 15px;
  color: #fff;
}
#home-navbar {
  display: none;
}
@media only screen and (max-width: 767px) {
  #avatarom .home.landing {
    height: auto;
    margin-top: 100px;
    padding: 10px 10px;
  }
  #avatarom .home.landing .title {
    padding-top: 150px;
  }
  #avatarom .home.landing .title .header {
    font-size: 1.5rem;
  }
  #avatarom .home.landing .title .header.main {
    font-size: 1em;
  }
  #avatarom .home.landing .navigation {
    display: none;
  }
  #avatarom .home.landing .searching {
    display: none;
  }
  #home-panel-search {
    display: none;
  }
  #home-navbar {
    display: block;
    top: 0;
  }
}
