#avatarom .top-categories {
  text-align: center;
  margin: 50px auto;
  max-width: 1200px;
}
#avatarom .top-categories .top-categories-header {
  text-align: center;
  margin-bottom: 30px;
}
#avatarom .top-categories .category-list {
  justify-content: center;
  background-color: transparent;
}
#avatarom .top-categories .category-list .category-item {
  margin: 8px;
  opacity: 1;
  font-size: 1em;
}
@media only screen and (max-width: 767px) {
  #avatarom .top-categories .category-list .category-item {
    margin: 3px;
    opacity: 1;
    font-size: 1em;
  }
}
