.navbar {
  position: fixed;
  left: 0;
  z-index: 10;
  width: 100%;
  box-shadow: black 1px 1px 8px;
}
.navbar .panel-search {
  padding: 0;
}
.navbar.mini .regions-search {
  display: none !important;
}
