.panel-search {
  background-color: #677E9B;
  padding: 10px;
}
.panel-search .content {
  margin: 0 !important;
}
.panel-search .employer-link {
  display: none;
}
@media only screen and (max-width: 767px) {
  #avatarom .panel-search {
    padding: 0;
  }
  #avatarom .panel-search .employer-link {
    display: block;
    color: #fff;
    padding-top: 10px;
  }
  #avatarom .panel-search .input {
    margin-top: 5px;
  }
  #avatarom .panel-search .input input {
    background: transparent;
    color: #fff;
    border-bottom: 1px solid #fff;
    border-radius: 10px;
    width: 350px;
  }
  #avatarom .panel-search .search-controls {
    padding-top: 0;
    padding-bottom: 10px;
  }
  #avatarom .panel-search .clearable-button {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 570px) {
  #avatarom .panel-search {
    padding: 0;
  }
  #avatarom .panel-search .input {
    margin-top: 5px;
  }
  #avatarom .panel-search .input input {
    background: transparent;
    color: #fff;
    border-bottom: 1px solid #fff;
    border-radius: 10px;
    width: 300px;
  }
  #avatarom .panel-search .search-controls {
    padding-right: 5px;
  }
}
@media only screen and (max-width: 500px) {
  #avatarom .panel-search {
    padding: 0;
  }
  #avatarom .panel-search .input {
    margin-top: 5px;
  }
  #avatarom .panel-search .input input {
    width: 200px;
  }
}
