#avatarom .company {
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  padding-top: 8px;
  margin: 15px 0;
  width: 360px;
  text-align: center;
  padding-top: 20px;
}
#avatarom .company-brand {
  margin: 10px 0;
  padding: 20px;
  text-align: center;
  background: white;
}
#avatarom .company-brand .company-brand-buttons .button {
  padding: 8px;
}
#avatarom .company-brand .company-brand-buttons .button i {
  margin-right: 6px !important;
}
@media only screen and (max-width: 767px) {
  #avatarom .company-brand .company-name {
    font-size: 2em !important;
  }
}
