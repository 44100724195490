#avatarom .search-around {
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  padding-top: 8px;
  margin: 15px 0;
  text-align: center;
  padding: 10px;
}
#avatarom .search-around .around-button {
  margin: 10px 0;
  height: 50px;
  width: 50px;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.3) !important;
  border-radius: 50px;
}
#avatarom .search-around .around-button i {
  color: #fff;
}
#avatarom .search-around .around-active {
  background: linear-gradient(60deg, hsl(224, 85%, 66%), hsl(269, 85%, 66%), hsl(314, 85%, 66%), hsl(359, 85%, 66%));
  box-shadow: 3px 7px 7px hsl(224, 85%, 66%) !important;
}
#around-distance {
  display: flex;
  position: fixed;
  z-index: 100;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 40px;
  text-align: center;
  justify-content: center;
}
#around-distance .around-distance-content {
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  padding-top: 8px;
  margin: 15px 0;
  background-color: #677E9B;
  color: #fff;
  margin: 0;
  padding: 5px 20px;
  border-radius: 100px;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.3) !important;
}
#around-distance .around-distance-content .header {
  color: #fff !important;
}
#around-distance .around-cancel-button {
  background: transparent;
  color: #677E9B;
}
#show-around-mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  #show-around-mobile {
    display: block;
    position: fixed;
    bottom: 55px;
    right: 15px;
    z-index: 10;
  }
  #around-distance {
    bottom: 25px;
    top: unset;
  }
  #around-distance .around-distance-content {
    padding: 0px 15px;
  }
}
