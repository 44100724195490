.search-base {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: inherit;
  justify-content: flex-start;
}
.search-base *:nth-child(n) {
  margin-right: 5px;
}
.search-base .items-search {
  display: inline-block;
}
.search-base .clearable-button {
  position: absolute;
  margin-left: -50px;
  margin-top: 5px;
  border: none;
  box-shadow: none !important;
  font-size: 0.8em;
  opacity: 0.8;
}
.search-base .search-button {
  padding: 10px 20px !important;
}
@media only screen and (max-width: 767px) {
  .search-base {
    justify-content: flex-end;
  }
}
