#avatarom .top-tags {
  text-align: center;
  margin: 10px auto;
  max-width: 1200px;
}
#avatarom .top-tags h2 {
  margin-bottom: 20px !important;
}
#avatarom .top-tags .top-tags-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: inherit;
  justify-content: center;
}
