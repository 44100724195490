#avatarom .regions {
  margin-top: 20px;
}
#avatarom .regions .region-link {
  width: 100%;
}
#avatarom .regions .region-link h3 {
  display: inline-block;
}
#avatarom .regions .region-link .region-label {
  margin-left: 20px;
}
