html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
}
#__next {
  height: 100%;
  width: 100%;
}
.message-dimmer {
  background: radial-gradient(circle, rgba(103, 126, 155, 0.9) 25%, rgba(19, 19, 19, 0.8) 60%) !important;
}
#avatarom {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#avatarom .content {
  flex: 1 0 auto;
}
#avatarom .content.with-navbar {
  margin-top: 55px;
}
#avatarom .header.light {
  margin: 8px 0;
  font-weight: normal;
}
#avatarom .header.primary {
  color: #404D6D;
}
#avatarom .header.secondary {
  color: #CF5D40;
}
#avatarom .meta {
  color: #707070;
  opacity: 0.8;
  margin: 0 5px;
}
#avatarom .link {
  color: #fff !important;
}
#avatarom .link.active {
  padding-bottom: 10px;
  border-bottom: 2px solid #CF5D40;
}
#avatarom .logo {
  display: inline-block;
  width: 150px;
}
#avatarom .search .results {
  width: 99%;
  border-radius: 16px;
}
#avatarom .search .results .result:hover {
  border-radius: 16px;
}
#avatarom .search .results .result.active {
  border-radius: 16px;
}
#avatarom .search .results .result .text-search {
  font-weight: bold;
}
#avatarom .search .results .result .search-items-count {
  float: right;
  background-color: #677E9B;
  color: #fff;
}
#avatarom .input input {
  font-size: 15px;
  width: 280px;
  border-radius: 12px;
  padding: 12px;
  border-color: transparent;
}
#avatarom .input i {
  width: 1em;
  color: #CF5D40;
  font-size: 1.5em;
  width: 1.5em;
  opacity: 1;
}
#avatarom .dropdown {
  width: 350px;
  border-radius: 12px;
}
#avatarom .dropdown input {
  width: 350px;
}
#avatarom .button {
  border-radius: 10px;
}
#avatarom .button i {
  height: auto;
  font-size: 1.5em;
  opacity: 1;
}
#avatarom .no-margin-grid {
  margin-right: 0;
  margin-left: 0;
}
#avatarom .button-white {
  background: #fff;
  color: #fff;
  font-weight: normal;
}
#avatarom .button-white:hover :focus {
  background: #fff;
}
#avatarom .button-white:active {
  background: #677E9B;
}
#avatarom .button-white:active i {
  color: #fff;
}
#avatarom .button-white i {
  color: #CF5D40;
}
#avatarom .button-orange {
  background: linear-gradient(0, #CF5D40, #CE7A41);
  color: #fff;
  font-weight: normal;
}
#avatarom .button-orange:hover :focus {
  background: linear-gradient(0, #CF5D40, #CE7A41);
}
#avatarom .button-orange:active {
  background: linear-gradient(0, #CE7A41, #CF5D40);
}
#avatarom .button-orange:active i {
  color: #fff;
}
#avatarom .button-orange i {
  color: #fff;
}
#avatarom .button-blue {
  background: #677E9B;
  color: #fff;
  font-weight: normal;
}
#avatarom .button-blue:hover :focus {
  background: #677E9B;
}
#avatarom .button-blue:active {
  background: #B5B4B4;
}
#avatarom .button-blue:active i {
  color: #fff;
}
#avatarom .button-blue i {
  color: #B5B4B4;
}
#avatarom .button-grey {
  background: #B5B4B4;
  color: #fff;
  font-weight: normal;
}
#avatarom .button-grey:hover :focus {
  background: #B5B4B4;
}
#avatarom .button-grey:active {
  background: #677E9B;
}
#avatarom .button-grey:active i {
  color: #fff;
}
#avatarom .button-grey i {
  color: #B5B4B4;
}
#avatarom .search-page {
  margin-top: 30px;
}
#avatarom .company-vacancies {
  margin-top: 35px;
}
#avatarom #home-panel-search {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}
@media only screen and (max-width: 730px) {
  #avatarom .content.with-navbar {
    margin-top: 100px;
  }
  #avatarom .search-page {
    margin-top: 20px;
  }
  #avatarom #home-panel-search {
    position: relative;
  }
  #avatarom .company-vacancies {
    margin-top: 25px;
  }
}
