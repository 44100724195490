.vacancy {
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  padding-top: 8px;
  margin: 15px 0;
}
.vacancy .vacancy-description .vacancy-description-content {
  margin-bottom: 5px;
  white-space: pre-wrap;
  word-break: break-word;
  font-family: sans-serif;
  color: black;
}
.vacancy .vacancy-description .vacancy-description-more {
  font-size: 1.1em;
}
.vacancy .vacancy-company {
  margin: 10px 0;
}
.vacancy .vacancy-tags-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: inherit;
  justify-content: flex-start;
  padding: 8px 0;
}
.vacancy .vacancy-tags-list .vacancy-tag {
  padding: 2px 8px;
  margin: 5px 10px 0 0;
  color: #fff;
  opacity: 0.85;
  font-size: 0.9em;
}
.vacancy .vacancy-metro {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: inherit;
  justify-content: flex-start;
}
.vacancy .vacancy-metro .metro-station {
  margin-right: 10px;
}
.vacancy .vacancy-metro .metro-station .metro-station-point {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10px;
}
.vacancy .vacancy-actions {
  padding-top: 10px;
}
.vacancy .vacancy-actions .favorite-action {
  text-align: right;
}
.vacancy .vacancy-actions .favorite-action .button {
  box-shadow: none;
}
.vacancy .vacancy-actions .favorite-action .button i {
  color: #B5B4B4;
}
.vacancy .vacancy-actions .favorite-action .button.favorite i {
  color: #CF5D40;
}
.vacancy .vacancy-extra {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #B5B4B4;
  opacity: 0.8;
}
.vacancy .vacancy-extra .category-link {
  float: right;
}
@media only screen and (max-width: 650px) {
  .vacancy .vacancy-actions .button {
    font-size: 0.9em;
  }
  .vacancy .vacancy-extra .vacancy-date {
    font-size: 0.8em;
  }
}
