#avatarom .vacancy-list {
  padding-top: 2px;
  padding-bottom: 20px;
}
#avatarom .vacancy-list .vacancy-list-header {
  padding-left: 8px;
  margin-bottom: 8px;
}
#avatarom .vacancy-list .vacancy-list-header h1 {
  font-size: 1.5em;
}
#avatarom .vacancy-list .vacancy-list-header h3 {
  font-size: 1.1em;
}
#avatarom .vacancy-list .sub-header {
  font-size: 1.1em;
}
#avatarom .vacancy-list .vacancy-list-left {
  max-width: 750px;
}
#avatarom .vacancy-list .vacancy-list-right {
  margin-top: 65px;
}
#avatarom .vacancy-list .vacancy-list-right .sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
}
@media only screen and (max-width: 767px) {
  #avatarom .vacancy-list {
    padding-left: 0px;
    padding-right: 0px;
  }
  #avatarom .vacancy-list .vacancy-list-right {
    margin-top: 0;
  }
  #avatarom .vacancy-list .vacancy-list-right .sticky {
    position: relative;
    top: 0;
  }
}
