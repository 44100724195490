#avatarom .category-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: inherit;
  justify-content: flex-start;
  padding: 7px 5px;
}
#avatarom .category-list .category-item {
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid #677E9B;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) !important;
}
#avatarom .category-list .category-item .category-item-link {
  font-size: 1em;
  color: #677E9B;
}
#avatarom .category-list .category-item .category-item-link .category-count {
  margin-left: 10px;
  margin-right: 3px;
  float: right;
}
