.top-professions {
  text-align: center;
  margin: 50px auto;
  max-width: 1200px;
}
.top-professions .top-professions-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: inherit;
  justify-content: center;
  margin: 20px 0;
}
.top-professions .top-professions-list .top-professions-list-item {
  padding: 30px;
}
.top-professions .top-professions-list .top-professions-list-item img {
  width: 150px;
}
@media only screen and (max-width: 1200px) {
  .top-professions {
    max-width: 800px;
  }
}
@media only screen and (max-width: 767px) {
  .top-professions .top-professions-list .top-professions-list-item {
    padding: 10px;
  }
  .top-professions .top-professions-list .top-professions-list-item img {
    width: 100px;
  }
  .top-professions .top-professions-list-item:last-child {
    display: none;
  }
}
