#avatarom .profession-container .profession-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: inherit;
  justify-content: flex-start;
  padding-bottom: 5px;
}
#avatarom .profession-container .profession-list .profession-item {
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid #677E9B;
  border-radius: 8px;
  background-color: #fff;
}
#avatarom .profession-container .profession-list .profession-item .profession-item-link {
  font-size: 1em;
  color: #677E9B;
}
#avatarom .profession-container .profession-list .profession-item .profession-item-link .profession-item-count {
  margin-left: 5px;
  color: #707070;
  font-size: 0.8em;
}
#avatarom .profession-container .more-professions {
  margin: 10px 20px;
  font-weight: normal;
  font-size: 0.9em;
  background: #fff;
  border: 1px solid #677E9B;
  color: #677E9B;
  padding: 10px 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3) !important;
}
#avatarom .profession-container .more-professions i {
  margin-right: 5px !important;
  font-size: 1.2em;
}
@media only screen and (max-width: 767px) {
  #avatarom .profession-container {
    padding: 0 5px;
  }
}
