#avatarom .address-map {
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  padding-top: 8px;
  margin: 15px 0;
  width: 360px;
  height: 320px;
  text-align: center;
}
#avatarom .address-map .header {
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  #avatarom .address-map {
    width: 100%;
  }
}
